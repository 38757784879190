/* styles.css */

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrolling */
}

.HomePage {
  position: relative;
  height: 100vh;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Ensure the background covers the entire viewport height */
  background: url('../public/glasses.jpg') center/cover no-repeat;
  background-color: black;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  color: white;
}

.content h1 {
  font-size: 3em; /* Adjust the font size as needed */
  font-weight: bolder;
}

p {
  color: rgb(86, 13, 155);
}

.navigationBar {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  background-color: #333; /* Example background color */
  color: white; /* Example text color */
  padding: 0px 20px; /* Example padding */
  width: calc(100% - 10px);
}

.navigationBar h1 {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.menus {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin-left: auto;
  margin-top: 0px;
  margin-bottom: 0px;
}

.menus li {
  padding: 20px 0px; /* Example spacing between menu items */
  width: 120px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0)
}

.menus li:hover {
  color: rgb(136, 45, 222); /* Change text color to purple when hovered over */
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.menus li:active {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

#selected {
  color: rgb(136, 45, 222);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.navigationBar button {
  /* Example button styling */
  padding: 10px 30px;
  margin-right: 10px;
  margin-left: 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navigationBar button:hover {
  background-color: #45a049; /* Darker green on hover */
  color: rgb(86, 13, 155);
  transform: translateY(-2px); /* Move button 2px up on hover for 3D effect */
}


.bottomText {
  position: fixed;
  bottom: 5%; /* Adjust as needed */
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}

.eyePurple {
  color: rgb(136, 45, 222);
}


.stackButton {
  display: none;
}

.responsiveLogin {
  display: none;
}

/* Media query for handheld devices */
@media only screen and (max-width: 768px) {
  .stackButton {
    display: flex;
  }
  .responsiveLogin {
    display: inline;
  }
  .menus {
    display: none;
    flex-direction: column; /* Change the direction to stack vertically */
    position: absolute;
    top: 55px; /* Adjust as needed */
    left: 0;
    width: 100%;
    background-color: #333; /* Example background color */
  }

  .menus.active {
    display: flex; /* Display the menu items when active */
  }

  .menus li {
    width: 100%; /* Take full width */
    transition: all 0.1s ease;
    border: 1px solid rgb(69, 69, 69); /* Example border for menu items */
  }

  .menus li:hover {
    box-shadow: 3px 3px #45a049, -1em 0 .4em rgb(136, 45, 222);
  }

  .menus li:active {
    box-shadow: inset 0 0 6px rgb(136, 45, 222);
  }

  .loginButton {
    display: none; /* Hide the login button on handheld devices */
  }
}


